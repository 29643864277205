<template>
	<div class="bigBox">
		<div class="">
			<el-page-header @back="goBack" content="新增设备详情" class="heardGoBack">
			</el-page-header>
			<el-divider></el-divider>
			<div class="newsText row-me">
				<div class="lefts">
					<!-- 基础信息 -->
					<div class="">
						<p>基础信息</p>
						<el-form ref="form" :model="form" label-position="right" label-width="100px">
							<div class="fontBox">
								<el-form-item label="IEME编码" required="true">
									<el-input placeholder="请输入" style="width: 70%;" clearable
										v-model="form.ieme_encoded"></el-input>
								</el-form-item>
								<el-form-item label="设备编码" required="true">
									<el-input placeholder="请输入" style="width: 70%;" clearable
										v-model="form.device_encoded"></el-input>
								</el-form-item>
								<el-form-item label="设备名称" required="true">
									<el-input placeholder="请输入" style="width: 70%;" clearable
										v-model="form.name"></el-input>
								</el-form-item>
								<el-form-item label="设备应用">
									<!-- <el-input placeholder="请输入" style="width: 70%;" clearable
										v-model="form.device_cate_id"></el-input> -->
									<el-select v-model="form.device_cate_id" placeholder="选择设备" class="electIpt"
										style="width: 70%;">
										<el-option v-for="(item,index) in comList" :key="item.id" :label="item.name"
											:value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="设备标签">
									<el-input placeholder="请输入" style="width: 70%;" clearable
										v-model="form.label"></el-input>
								</el-form-item>
								<el-form-item label="所在地区">
									<el-input placeholder="请输入" style="width: 70%;" clearable
										v-model="form.areainfo"></el-input>
								</el-form-item>
								<el-form-item label="转速" required="true">
									<el-input placeholder="请输入" style="width: 70%;" clearable
										v-model="form.speed"></el-input>
								</el-form-item>
								<el-form-item label="荷载状况">
									<!-- 荷载状况：1：轻，2：中，3：重 -->
									<el-select v-model="form.load_state" placeholder="荷载状况" class="electIpt"
										style="width: 70%;">
										<el-option label="轻" value="1"></el-option>
										<el-option label="中" value="2"></el-option>
										<el-option label="重" value="3"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="工作温度">
									<el-select v-model="form.industry_temp" placeholder="工作温度" class="electIpt"
										style="width: 70%;">
										<el-option label="47°C ≤ 温度 < 63°C" value="1"></el-option>
										<el-option label="63°C ≤ 温度 < 78°C" value="2"></el-option>
										<el-option label="78°C ≤ 温度 < 93°C" value="3"></el-option>
										<el-option label="93°C ≤ 温度 < 107°C" value="4"></el-option>
									</el-select>
								</el-form-item>
							</div>
						</el-form>
					</div>
					<!-- 传感设置 -->
					<div>
						<p class="margin-top50">参数设定</p>
						<el-form ref="form" :model="form" label-position="right">
							<div class="fontBox">
								<!-- <el-form-item label="电流传感器" required="true">
									<el-switch v-model="valueSwich">
									</el-switch>
								</el-form-item> -->
								<el-form-item label="循环阈值" required="true">
									<div class="column-me row-center">
										<el-radio v-model="form.threshold" label="1" class="margin-top18">循环次数（次）
											<el-input onkeyup="value=value.replace( /[^\d]/g,'')" placeholder="请输入"
												v-model="form.threshold_loop" style="width: 100px;"
												@blur="threshold_loop()"></el-input>
										</el-radio>
										<el-radio v-model="form.threshold" label="2" class="margin-top18">喷油时间（秒）
											<el-input onkeyup="value=value.replace( /[^\d]/g,'')" placeholder="请输入"
												v-model="form.threshold_time" style="width: 100px;"
												@blur="threshold_time()"></el-input>
										</el-radio>
										<div class="row-me margin-top18">
											<span class="font-size18">间隔时间(小时)</span>
											<el-input onkeyup="value=value.replace( /[^\d]/g,'')" placeholder="请输入"
												v-model="form.interval_time" style="width: 100px;margin-left: 10px;"
												@blur="interval_time()">
											</el-input>
										</div>
										<!-- <div class="row-me margin-top18">
											<span class="font-size18">标准喷油时间(秒)</span>
											<el-input placeholder="请输入" v-model="form.standard_time"
												style="width: 100px;margin-left: 10px;">
											</el-input>
										</div> -->
									</div>
									<div class="machineBox" v-if="type != 0">
										<el-form-item label="手动启停控制">
											<el-switch v-model="machine" @change="machineOnch">
											</el-switch>
										</el-form-item>
									</div>
								</el-form-item>
							</div>
						</el-form>
					</div>
					<!-- 传感器警告设置 -->
					<!-- <div class="">
						<p> 传感器警告设置</p>
						<el-form ref="form" :model="form" label-position="right" label-width="120px">
							<div class="fontBox">
								<el-form-item label="循环警告阈值">
									<el-input placeholder="请输入内容" v-model="input1" class="width400">
										<template #prepend>循环时间（min）</template>
									</el-input>
								</el-form-item>
								<el-form-item label="压力警告阈值">
									<div class="column-me">
										<div class="">
											<el-input placeholder="请输入" v-model="input1" class="width200">
												<template #prepend>低压值</template>
											</el-input>
											<el-input placeholder="请输入" v-model="input1" class="width200">
												<template #prepend>持续时间</template>
											</el-input>
										</div>
										<div class="">
											<el-input placeholder="请输入" v-model="input1" class="width200">
												<template #prepend>高压值</template>
											</el-input>
											<el-input placeholder="请输入" v-model="input1" class="width200">
												<template #prepend>持续时间</template>
											</el-input>
										</div>
									</div>

								</el-form-item>

							</div>
						</el-form>
					</div> -->
				</div>
				<div class="rights">
					<el-form ref="form" :model="form" label-position="right" label-width="120px">
						<el-form-item label="现场照片">
							<div class="row-me goodsEdit">
								<!-- <div class="row-me row-center flex-wrap flex1">
									<div class="posRelative margin-right20 margin-bottom20"
										v-for="(item,index) in form.images" :key="index">
										<el-image :src="item" :preview-src-list="[item]" fit="scale-down"
											class="img-goods" hide-on-click-modal>
											<template #error>
												<img src="../../assets/img/no_img.png" slot="error" class="objFitCover">
											</template>
										</el-image>
										<img src="../../assets/img/close.png" class="img-delete pointer"
											@click="form.images.splice(index,1)">
									</div>
									<el-upload class="avatar-uploader" action="" :show-file-list="false" multiple
										:before-upload="beforeAvatarUpload" accept=".jpg,.jpeg,.png" :auto-upload="true"
										:http-request="uploadFile">
										<img src="../../assets/img/icon_sc.png" />
										<div class="putTxt">点击上传</div>
									</el-upload>
								</div> -->
								<img :src="form.images" alt="" class="imgF" v-if="form.images != ''" />
								<singleUploadQiniu v-model="form.images" @input="getName"></singleUploadQiniu>
							</div>

						</el-form-item>
						<el-form-item label="轴承型号">
							<div class="column-me">
								<!-- <div class="">
									<span>开启二组型号</span>
									<el-switch v-model="value1" />
								</div> -->
								<div class="row-me">
									<div class="margin-top10">
										<div class="column-me">
											<img src="../../assets/img/Snipaste.png" alt="" class="typeImg" />
											<el-table :data="tableData" border
												header-cell-class-name="table-header-style"
												cell-class-name="table-cell-style" class="tabBox">
												<el-table-column prop="seat_name" label="位置"></el-table-column>
												<el-table-column prop="name" label="轴承型号1">
													<template #default="scope">
														<el-tooltip :disabled="disabledTooltip" effect="dark"
															:content="scope.row.modelA" placement="top">
															<el-input ref="lifeComNameStr" placeholder="请输入"
																v-model="scope.row.modelA" type="text"
																class="control" />
														</el-tooltip>
													</template>
												</el-table-column>
												<!-- <el-table-column prop="modelA" label="轴承型号1"
													:show-overflow-tooltip="true" :render-header="renderHeader" /> -->
												<el-table-column prop="modelA" label="轴承型号2">
													<template #default="scope">
														<el-tooltip :disabled="disabledTooltip" effect="dark"
															:content="scope.row.modelB" placement="top">
															<el-input ref="lifeComNameStr" placeholder="请输入"
																v-model="scope.row.modelB" type="text"
																class="control" />
														</el-tooltip>
													</template>
												</el-table-column>
												<!-- <el-table-column prop="address" label="喷油量(ml)"></el-table-column> -->
											</el-table>
											<!-- 柱状图 -->
											<div class="earchs">
												<span class="titTxt">本月油脂消耗01</span>
												<div ref="chart" class="chartBox"></div>
												<!-- <div ref="chart" class="chartBox" style="width:400px;height:260px"></div> -->
											</div>
										</div>
									</div>

									<div class="margin-left20" v-show="value1==true">
										<div class="column-me margin-top10">
											<img src="../../assets/img/Snipaste2.png" alt="" class="typeImg">
											<el-table :data="tableData1" border
												header-cell-class-name="table-header-style"
												cell-class-name="table-cell-style" class="tabBox">
												<el-table-column prop="seat_name" label="位置"></el-table-column>
												<el-table-column prop="name" label="轴承型号1">
													<template #default="scope">
														<el-tooltip :disabled="disabledTooltip" effect="dark"
															:content="scope.row.modelA" placement="top">
															<el-input ref="lifeComNameStr" placeholder="请输入"
																v-model="scope.row.modelA" type="text"
																class="control" />
														</el-tooltip>
													</template>
												</el-table-column>
												<el-table-column prop="modelA" label="轴承型号2">
													<template #default="scope">
														<el-tooltip :disabled="disabledTooltip" effect="dark"
															:content="scope.row.modelB" placement="top">
															<el-input ref="lifeComNameStr" placeholder="请输入"
																v-model="scope.row.modelB" type="text"
																class="control" />
														</el-tooltip>
													</template>
												</el-table-column>
												<!-- <el-table-column prop="address" label="喷油量(ml)"></el-table-column> -->
											</el-table>
											<!-- 柱状图 -->
											<div class="earchs">
												<span class="titTxt">本月油脂消耗</span>
												<div ref="chart1" class="chartBox"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<el-divider></el-divider>
			<div class="btnsTwo row-me row-center flex-end">
				<el-button class="btns" @click="goBack">取消</el-button>
				<el-button class="btns" type="primary" @click="saveClick">保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import singleUploadQiniu from '@/components/singleUploadQiniu.vue' //上传文件
	export default {
		components: {
			singleUploadQiniu
		},
		data() {
			return {
				disabledTooltip: false,
				name: '',
				valueSwich: false,
				radio: '1',
				input1: "",
				size: "small",
				form: {
					ieme_encoded: '',
					device_encoded: '',
					name: '',
					speed: '',
					threshold: '1',
					bearModelData: [],
					bearing_level: 1,
					images: '',
				},
				value1: true,
				tableData: [{
					seat_name: 'M1',
					group_num: 1,
					modelA: '',
					modelB: '',
				}, {
					seat_name: 'M2',
					group_num: 1,
					modelA: '',
					modelB: '',
				}, {
					seat_name: 'S1',
					group_num: 1,
					modelA: '',
					modelB: '',
				}, {
					seat_name: 'S2',
					group_num: 1,
					modelA: '',
					modelB: '',
				}],
				tableData1: [{
					seat_name: 'M3',
					group_num: 2,
					modelA: '',
					modelB: '',
				}, {
					seat_name: 'M4',
					group_num: 2,
					modelA: '',
					modelB: '',
				}, {
					seat_name: 'S3',
					group_num: 2,
					modelA: '',
					modelB: '',
				}, {
					seat_name: 'S4',
					group_num: 2,
					modelA: '',
					modelB: '',
				}],
				data1: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
				data2: [120, 200, 150, 80, 70, 110, 130],
				comList: [],
				seriesData: [],
				seriesData1: [],
				machine: false, //手动启
				type: 0,
			}
		},
		mounted() {
			this.type = this.$route.query.type; // 0新增
			if (this.type == 0) {
				this.value1 = false;
			}
			this.deviceInfoOptionWay();

			if (this.$route.query.type == 1) {
				this.form.id = this.$route.query.id;
				this.$post("/index/deviceDetails", {
					id: this.form.id
				}).then((res) => {
					if (res) {
						this.form = res.data;

						if (res.data.machine == 'OFF') {
							this.machine = false;
						} else {
							this.machine = true;
						}

						this.form.threshold_loop1 = this.form.threshold_loop; //循环次数
						this.form.threshold_time1 = this.form.threshold_time; //喷油时间
						this.form.interval_time1 = this.form.interval_time; //间隔时间

						if (res.data.load_state != '') {
							this.form.load_state = res.data.load_state.toString()
						}
						if (res.data.load_state == 0) {
							this.form.load_state = '';
						}
						if (res.data.industry_temp != '') {
							this.form.industry_temp = res.data.industry_temp.toString()
						}
						if (res.data.industry_temp == 0) {
							this.form.industry_temp = '';
						}
						if (res.data.threshold != '' && res.data.threshold != null) {
							this.form.threshold = res.data.threshold.toString()
						}


						//轴承型号，1：一组，2：二组，如果是1，则只显示第一组，如果是2，则显示两组
						if (res.data.bearing_level == 1) {
							this.value1 = false;
							this.tableData = this.form.bearModelData[0];
							this.tableData1 = this.form.bearModelData[1];
						} else {
							this.value1 = true;
							this.tableData = this.form.bearModelData[0];
							this.tableData1 = this.form.bearModelData[1];
						}
						if (res.data.deviceGrease[0].dataInfo.length > 0) {
							this.seriesData = res.data.deviceGrease[0].dataInfo;
						} else {
							this.seriesData = [0, 0, 0, 0];
						}
						if (res.data.deviceGrease[1].dataInfo.length > 0) {
							this.seriesData1 = res.data.deviceGrease[1].dataInfo;
						} else {
							this.seriesData1 = [0, 0, 0, 0];
						}
						this.chartWay();
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			} else {
				this.seriesData = [0, 0, 0, 0];
				this.seriesData1 = [0, 0, 0, 0];
				this.chartWay();
			}

		},
		methods: {
			showTooltip() {
				const lifeW = this.$refs.lifeComNameStr
				this.disabledTooltip = lifeW.value.length < 2
			},
			machineOnch() {
				var machine;
				if (this.machine == false) {
					machine = 'OFF'
				} else {
					machine = 'ON'
				}
				var data = {
					id: this.form.id,
					machine: machine,
				}
				this.$post("/index/deviceMachineOperate", data).then((res) => {
					if (res) {
						this.$message.success('修改成功');
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},

			// 设备参数单独修改接口
			deviceConfigSaveWay(field_name, value) {
				this.$post("/index/deviceConfigSave", {
					id: this.$route.query.id,
					field_name: field_name,
					value: value
				}).then((res) => {
					if (res) {
						// this.comList = res.data;
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			// 失去焦点-间隔时间
			interval_time() {
				if (this.$route.query.type == 1) {
					if (this.form.interval_time1 != this.form.interval_time) {
						this.deviceConfigSaveWay('interval_time', this.form.interval_time);
						this.form.interval_time1 = this.form.interval_time;
					}
				}

			},
			// 失去焦点-喷油时间
			threshold_time() {
				if (this.$route.query.type == 1) {
					if (this.form.threshold_time1 != this.form.threshold_time) {
						this.deviceConfigSaveWay('threshold_time', this.form.threshold_time);
						this.form.threshold_time1 = this.form.threshold_time;
					}
				}

			},
			// 失去焦点-循环次数
			threshold_loop() {
				if (this.$route.query.type == 1) {
					if (this.form.threshold_loop1 != this.form.threshold_loop) {
						this.deviceConfigSaveWay('threshold_loop', this.form.threshold_loop);
						this.form.threshold_loop1 = this.form.threshold_loop;
					}
				}
			},
			getName(data) {
				this.form.images = data;
			},
			chartWay() {
				const myChart = this.$echarts.init(this.$refs.chart)
				myChart.setOption({
					xAxis: {
						type: 'category',
						data: ['M1', 'M2', 'S1', 'S2'],
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						// data: [0, 0, 0, 0],
						data: this.seriesData,
						type: 'bar'
					}]
				})

				const myChart1 = this.$echarts.init(this.$refs.chart1)
				myChart1.setOption({
					xAxis: {
						type: 'category',
						data: ['M3', 'M4', 'S3', 'S4'],
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						// data: [0, 0, 0, 0],
						data: this.seriesData1,
						type: 'bar'
					}]
				})

			},

			// 获取应用设备
			deviceInfoOptionWay() {
				this.$post("/admin/deviceCateOption", {}).then((res) => {
					if (res) {
						this.comList = res.data;
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			goBack() {
				this.$router.go(-1);
			},
			// 点击保存
			saveClick() {
				this.form.bearModelData = [];
				if (this.form.ieme_encoded == '') {
					this.$message.error('IEME编码不能为空');
					return false;
				}
				if (this.form.device_encoded == '') {
					this.$message.error('设备编码不能为空');
					return false;
				}
				if (this.form.name == '') {
					this.$message.error('设备名称不能为空');
					return false;
				}
				if (this.form.speed == '') {
					this.$message.error('转速不能为空');
					return false;
				}
				// 循环阈值：1：循环次数，2：喷油时间
				this.form.threshold = Number(this.form.threshold)
				if (this.form.threshold == 1) {
					delete this.form.threshold_time
				} else if (this.form.threshold == 2) {
					delete this.form.threshold_loop
				}
				if (this.$route.query.type == 0) {
					delete this.form.id
				}
				// 轴承型号，1：一组，2：二组，如果是1，则只显示第一组，如果是2，则显示两组
				// if (this.form.bearing_level == 1) {
				// 	this.form.bearModelData.push(this.tableData);
				// } else if (this.form.bearing_level == 2) {
				// 	this.form.bearModelData.push(this.tableData);
				// 	this.form.bearModelData.push(this.tableData1);
				// }
				this.form.bearModelData.push(this.tableData);
				this.form.bearModelData.push(this.tableData1);
				console.log(this.form, '55555555');
				// return false;
				this.$post("/index/deviceInfoAdd", this.form).then((res) => {
					if (res) {
						this.$message.success('保存成功');
						this.$router.go(-1);
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})

			},
			//上传图片验证
			beforeAvatarUpload(file) {
				let types = ['image/jpeg', 'image/jpg', 'image/png'];
				const isImage = types.includes(file.type);
				if (!isImage) {
					this.$message.error('上传图片只支持JPG、JPEG、PNG格式!')
					return false
				}
				return true
			},
			//上传
			uploadFile(param) {
				let file = param.file;
				let formData = new FormData();
				formData.append("file", file);
				this.$uploadFile('/api/uploadFiles', formData).then((res) => {
					if (res) {
						if (this.$method.isEmpty(this.form.images)) {
							this.form.images = []
						}
						this.form.images.push(res.data)
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.goodsEdit {
		position: relative;

		.imgF {
			width: 2.493333rem;
			height: 1.866667rem;
			position: absolute;
			top: 0;
			cursor: pointer;
			pointer-events: none;
		}

		.img-goods {
			width: 187px;
			height: 140px;
		}

		.objFitCover {
			width: 187px;
			height: 140px;
		}

		.img-delete {
			position: absolute;
			top: -10px;
			right: -10px;
			width: 30px;
			height: 30px;
		}

		.left-title {
			width: 120px;
			text-align: right;
			margin-right: 10px;
		}

		/deep/ .avatar-uploader .el-upload {
			width: 187px;
			height: 140px;
			display: flex;
			flex-direction: column;

			.putTxt {
				font-size: 16px;
				color: #9D9FA3;
			}
		}
	}

	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
		height: calc(100vh - 180px);
		overflow-y: scroll;

		.typeImg {
			width: 444px;
			height: 150px;
			border: 1px solid #D6D9E2;
			margin-bottom: 20px;
		}

		.tabBox {
			width: 444px;
		}

		.btnsTwo {
			// margin-left: 1360px;
		}

		.btns {
			width: 100px;
			height: 40px;
			font-size: 18px;
		}

		.newsText {
			width: 100%;
			height: auto;

			p {
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				line-height: 21px;
				color: #252630;
			}

			.fontBox {
				font-size: 16px !important;
				// margin-left: 49px;
				margin-top: 30px;
			}

			.lefts {
				width: 35%;
				height: 100%;
			}

			.rights {
				width: 60%;
				height: 100%;

				/deep/.el-form-item__label {
					padding-top: 10px;
					// margin-top: 100px;
				}
			}

			.machineBox {
				margin-top: 20px;

				/deep/.el-form-item__label {
					font-size: 18px !important;
				}
			}

			.earchs {
				width: 444px;
				height: 3rem;
				// height: 280px;
				margin-top: 20px;
				text-align: center;
				background-color: #fff;
				position: relative;
				border: 1px solid #D6D9E2;
				// background-color: #D6D9E2;

				.titTxt {
					width: 100%;
					// background-color: indianred;
					position: absolute;
					top: 10px;
					left: 0;
					font-size: 16px;
					font-weight: bold;
				}

				.chartBox {
					// width: 400px;
					// height: 260px;
					width: 100%;
					height: 3.5rem;
					margin-top: -10px;
				}
			}
		}
	}

	::v-deep .el-form-item__label {
		font-size: 16px !important;
	}
</style>